<template>
  <div class="wrapped-text">
    <div v-for="(line, index) in lines" :key="index" class="wrapped-line" :class="lineClass">
      <strong v-if="index == 0 && label">{{label}}:</strong>
      <span v-html="line"/>
    </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";

export default {
  props: {
    text: {default: ""},
    label: {type: String, required: false},
    lineClass: {type: String, required: false},
  },
  computed: {
    lines(){
      const md = new MarkdownIt({html:false, linkify:true, breaks: false});
      const text = md.render(this.text)
      return (text || "").toString().split("\n")
    }
  }
}
</script>

<style lang="sass">
  .wrapped-line
    word-break: break-word
    & > span
      & > p
        margin-bottom: 0 !important
</style>